@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=JetBrains+Mono:wght@500&family=Space+Grotesk:wght@600;700&display=swap');

$unanswered-color: rgba(116, 61, 251, 1);
$answered-color: rgba(95, 255, 180, 1);
$main-background-color: #e6edf5;
$table-border: rgba(255, 51, 102, 1);
$score-table-border: rgba(224, 232, 242, 1);
$table-box-shadow: rgba(15, 0, 40, 1);
$light-red: rgba(255, 51, 102, 0.40);
$light-orange: rgba(254, 214, 49, 1);
$light-green: rgba(95, 255, 180, 0.80);
$light-purple: rgba(116, 61, 251, 1);
$primary-button: rgba(116, 61, 251, 1);
$primary-button-hover: #2b1266;
$primary-button-font: #fff;
$view-results-button-color: rgba(95, 255, 180, 0.80);
$secondary-button: rgba(116, 61, 251, 1);
$secondary-button-hover: rgba(254, 214, 49, 1);
$overview-button: rgba(255, 51, 102, 1);
$overview-button-hover: rgba(255, 51, 102, 0.8);
$flag-color: rgba(116, 61, 251, 1);
$flag-hover-color: rgba(255, 51, 102, 1);
$flag-hover-bg-color: rgba(255, 255, 255, 1);
$flag-pressed: rgba(255, 51, 102, 1);
$results-headings: rgba(116, 61, 251, 1);
$font-color: rgb(15, 0, 40);
$question-nav-border: rgba(15, 0, 40, 1);
$edit-button: rgba(116, 61, 251, 1);
$failure-message: rgba(198, 56, 6, 1);
$success-message: rgba(95, 255, 180, 1);
$black: rgba(0, 0, 0, 1);
$test-not-taken-button: rgba(116, 61, 251, 0.40);
$modal-pale-purple: rgba(116, 61, 251, 0.40);
$modal-pink-results-card: rgba(255, 51, 102, 0.40);
$test-not-taken: rgba(255, 255, 255, 1);
$purple-button-color: rgba(116, 61, 251, 1);
$energyGreen: rgba(95, 255, 180, 1);
$energyGreen80: rgba(95, 255, 180, 0.80);
$energyGreen40: rgba(95, 255, 180, 0.40);
$energyGreen15: rgba(95, 255, 180, 0.15);
$coolGrey: rgba(224, 232, 242, 1);
$coolGrey80: rgba(224, 232, 242, 0.80);
$coolGrey40: rgba(224, 232, 242, 0.40);
$coolGrey15: rgba(224, 232, 242, 0.15);
$modal-background: rgba(224, 232, 242, 1);
$overlay-background: rgba(0,0,0,0.15);
$warning-text: rgba(255, 51, 102, 1);
$white :rgba(255, 255, 255, 1);
$pink-table-grade: rgba(255, 51, 102, 0.40);
$purple-table-grade: rgba(116, 61, 251, 0.40);
$green-table-grade: rgba(95, 255, 180, 0.40);
$purple-error-message: rgba(15, 0, 40, 1);

@mixin headingOneFontStyles {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  font-size: 4.8rem;
}

@mixin headingTwoFontStyles {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  font-size: 4rem;
}

@mixin headingThreeFontStyles {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
}

@mixin headingFourFontStyles {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
}

@mixin bodyFontStyles {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 2.8rem;
}

@mixin buttonsMetaCapsFontStyles {
  font-family: 'JetBrains Mono', monospace;
  font-weight: 500;
  font-size: 2rem;
  text-transform: uppercase;
}

@mixin buttonsMetaFontStyles {
  font-family: 'JetBrains Mono', monospace;
  font-weight: 500;
  font-size: 2rem;
}
