@import "imports/modal";
@import "imports/variables";

html {
  font-size: 62.5%;

  body {
    color: $font-color;
    background-color: $main-background-color;
    @include bodyFontStyles;

    h1 {
      color: $font-color;
      @include headingOneFontStyles;
    }

    h2 {
      color: $font-color;
      @include headingTwoFontStyles;
    }

    h3 {
      color: $font-color;
      @include headingThreeFontStyles;
    }

    h4 {
      color: $font-color;
      @include headingFourFontStyles;
    }

    label{
      @include headingFourFontStyles
    }

    input, select, select:not([multiple]) {
      border-radius: 4px;
    }

    .container {

      input {
        font-size: 1.4rem;
      }

      .labelDropdown {
        font-size: 2rem;
      }

      .dropdown {
        font-size: 1.5rem;
      }

      .user_list {
        padding: 20px;
      }
    }

    .score_list {
      padding: 20px;
    }

    img {
      width: 100%;
    }

    .logo {
      width: 300px;
      height: 100px;
      padding: 30px;
      margin: 10px auto;
      display: block;
    }

    p {
      display: inline; // WHY!? im too afraid to change this...
      margin: 10px;
    }

    .custom-select {
      background-color: white;
      font-size: 1.5rem;
    }

    .primary-button {
      background-color: $primary-button;
      color: $primary-button-font;
      transition: .25s;
      border-radius: 4px;
      padding: 6px 12px;
      border-style: none;
      @include buttonsMetaFontStyles;

      &:hover {
        background-color: $primary-button-hover;
        color: $primary-button-font;
      }
    }

    .edit-user-button:hover,
    .more-info-button:hover,
    .view-results-button:hover,
    .download-user-results-button:hover {
      background-color: $energyGreen;
      color: $font-color;
      transition-duration: .25s;
    }

    .delete-user-button:hover {
      background-color: $energyGreen;
      color: $font-color;
      transition-duration: .25s;
    }

    .search-heading {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .editUserData {
    font-size: 1.5rem;
  }

}

.no-flex {
  display: block;
}
