@import "variables";

.overlay {
  background: $overlay-background;
  position: fixed;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

#modal {
  display: none;
  background: $modal-background;
  width: 650px;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  z-index: 501;
}

.list-container {
  margin: 0 70px 0;
  float: left;
}

.modal-button-holder {
  text-align: center;
}

.modal-title {
  text-align: center;
}

.list-wrapper {
  overflow: auto;
}

#view-results-modal {
  display: none;
  background: $modal-background;
  width: 70vw;
  height: 90vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 501;
  margin: auto;
  padding: 10px 50px;
  overflow-y: scroll;

  .rightAnswer {
    background: $green-table-grade;
  }
  .wrongAnswer {
    background: $pink-table-grade;
  }
}

